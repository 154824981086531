import React, { useState, useEffect, useContext } from 'react';
import { Box, LinearProgress, Container } from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import ApiService from '../../services/apiService';
import { UserContext } from '../../contexts/UserProvider';
import { useTranslation } from 'react-i18next';
import AppSnackbar from './../AppSnackbar';

function ServiceTests() {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState(null);

    useEffect(() => {
        requestItems(userInfo?.idToken);
    }, [userInfo?.idToken]);

    const requestItems = async (idToken) => {
        setIsLoading(true);
        try {
            const items = await ApiService.getMapTesters(idToken);
            setItems(items);
        } catch (error) {
            setSnackbarInfo({ message: t(error.message), severity: error.severity });
        }
        setIsLoading(false);
    };

    return (
        <>
        { isLoading && (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>)}
            <Container maxWidth="xl">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <h1>{t("Map Testers")}</h1>
                </Box>
                {items &&
                <TableContainer>
                    <Table size='small' padding='small'>
                        <colgroup>
                            <col style={{width:'20%'}}/>
                            <col style={{width:'20%'}}/>
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>{t("Version")}</strong></TableCell>
                                <TableCell><strong>{t("Open")}</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.version}</TableCell>
                                    <TableCell><a href={item.url} target='_blank'>Open</a></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </Container>
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
}

export default ServiceTests;