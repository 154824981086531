import React from 'react';
import { useTranslation } from 'react-i18next';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

const PlotlyRenderers = createPlotlyRenderers(Plot);

function TasksByVehiclePivot({ items }) {
    const { t } = useTranslation();
    const [pivotState, setPivotState] = React.useState({
        rows: ['Company Id','Day', 'Vehicle Id'],
        cols: [],
        rendererName: 'Table Heatmap',
        aggregatorName: 'Sum',
        vals: ['Number'],
    });

    return (
        <div>
            {items &&
            <PivotTableUI
                data={items}
                onChange={s => setPivotState(s)}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...pivotState}
            />
            }
        </div>
    );
}

export default TasksByVehiclePivot;