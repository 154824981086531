import axios from 'axios';
import ApiError from './apiError';

const apiUrl = process.env.REACT_APP_ENV !== 'local' ?
    `https://admin.onerbox.com/service/${process.env.REACT_APP_VERSION}/api` :
    'http://localhost:5010/api';

export default class ApiServiceHttp {

    static async defaultRequest(idToken, url, method, data) {
        try
        {
            let requestUrl = `${apiUrl}/${url}`;
            if (method.toLowerCase() === 'get' && data) {
                const queryString = new URLSearchParams(data).toString();
                requestUrl += `?${queryString}`;
            }
            const response = await axios.request({
                url: requestUrl,
                method: method,
                data: method.toLowerCase() === 'get' ? undefined : data,
                headers: {
                    Authorization: `Bearer ${idToken}`,
                }
            });
            return response.data;
        }
        catch (error)
        {
            if (error.response?.status === 401) {
                throw new ApiError("Unauthorized.","error");
            }
            throw new ApiError("Error in request.","error");
        }
    }

    // User info
    static async getUserInfo(idToken) {
        return await this.defaultRequest(idToken, 'user', 'get');
    }

    // Organizations
    static async getOrganizations(idToken) {
        return await this.defaultRequest(idToken, 'organizations', 'get');    
    }

    static async getOrganization(idToken, id) {
        return await this.defaultRequest(idToken, `organizations/${id}`, 'get');
    }

    static async saveOrganization(idToken, data) {
        return await this.defaultRequest(idToken, 'organizations', 'post', data);
    }

    static async updateOrganization(idToken, data) {
        return await this.defaultRequest(idToken, 'organizations', 'put', data);
    }

    static async deleteOrganization(idToken, data) {
        return await this.defaultRequest(idToken, `organizations/${data.id}`, 'delete');
    }

    // Companies
    static async getCompanies(idToken) {
        return await this.defaultRequest(idToken, 'companies', 'get');
    }

    static async getCompany(idToken, id) {
        return await this.defaultRequest(idToken, `companies/${id}`, 'get');
    }

    static async saveCompany(idToken, data) {
        return await this.defaultRequest(idToken, 'companies', 'post', data);
    }

    static async updateCompany(idToken, data) {
        return await this.defaultRequest(idToken, 'companies', 'put', data);
    }

    static async deleteCompany(idToken, data) {
        return await this.defaultRequest(idToken, `companies/${data.id}`, 'delete');
    }

    // Licenses
    static async getLicenses(idToken) {
        return await this.defaultRequest(idToken, 'licenses', 'get');
    }

    static async getLicense(idToken, id) {
        return await this.defaultRequest(idToken, `licenses/${id}`, 'get');
    }

    static async saveLicense(idToken, data) {
        return await this.defaultRequest(idToken, 'licenses', 'post', data);
    }

    static async updateLicense(idToken, data) {
        return await this.defaultRequest(idToken, 'licenses', 'put', data);
    }

    static async deleteLicense(idToken, data) {
        return await this.defaultRequest(idToken, `licenses/${data.id}`, 'delete');
    }

    // Licenses History
    static async getLicensesHistory(idToken, start, end, companyId, licenseName) {
        return await this.defaultRequest(idToken, 'licenseshistory', 'get', { start, end, companyId, licenseName });
    }

    // Tasks
    static async getTasksHistory(idToken, start, end, companyId, licenseName, notSuccessful) {
        return await this.defaultRequest(idToken, 'taskshistory', 'get', 
            { start, end, companyId, licenseName, notSuccessful });
    }

    static async getTasksByVehicle(idToken, start, end, companyId, licenseName) {
        return await this.defaultRequest(idToken, 'taskshistory/byvehicle', 'get', 
            { start, end, companyId, licenseName });
    }

    // Requests
    static async getRequests(idToken, start, end, companyId, licenseName) {
        return await this.defaultRequest(idToken, 'requests', 'get', { start, end, companyId, licenseName });
    }

    // Analysis data
    static async getAnalysisData(idToken, start, end, companyId, licenseName, service, type) {
        return await this.defaultRequest(idToken, 'analysisdata', 'get', { start, end, companyId, licenseName, service, type });
    }

    // GDC Routes
    static async getGdcRoutes(idToken) {
        return await this.defaultRequest(idToken, 'gdcroutes', 'get');
    }

    static async getGdcRoute(idToken, companyId, routeId) {
        return await this.defaultRequest(idToken, `gdcroutes/${companyId}/${routeId}`, 'get');
    }

    static async saveGdcRoute(idToken, data) {
        return await this.defaultRequest(idToken, 'gdcroutes', 'post', data);
    }

    static async deleteGdcRoute(idToken, companyId, routeId) {
        return await this.defaultRequest(idToken, `gdcroutes/${companyId}/${routeId}`, 'delete');
    }

    static async simulateUserLocations(idToken, companyId, routeId) {
        return await this.defaultRequest(idToken, `gdcroutes/${companyId}/${routeId}/simulateuserlocations`, 'post');
    }

    // GDC Users
    static async getGdcUsers(idToken) {
        return await this.defaultRequest(idToken, 'gdcusers', 'get');
    }

    // GDC History
    static async getGdcHistory(idToken, start, end, companyId, licenseName) {
        return await this.defaultRequest(idToken, 'gdchistory', 'get', 
            { start, end, companyId, licenseName });
    }

    // Service tests
    static async getServiceTests(idToken) {
        return await this.defaultRequest(idToken, 'servicetests', 'get');
    }

    static async runTest(idToken, testRun) {
        return await this.defaultRequest(idToken, 'servicetests/runtest', 'post', testRun);
    }

    static async getMapTesters(idToken) {
        return await this.defaultRequest(idToken, 'servicetests/maptesters', 'get');
    }
}
