import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableContainer, Menu, MenuItem, Chip, Tooltip, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from '../../controls/Formatters';
import AppSnackbar from './../../AppSnackbar';

function CompaniesTable({ items }) {
    const { t } = useTranslation();
    const [snackbarInfo, setSnackbarInfo] = useState(null);

    return (
        <div>
            {items &&
            <>
            <TableContainer>
                <Table size='small' padding='small'>
                    <colgroup>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>{t("DateTime")}</strong></TableCell>
                            <TableCell><strong>{t("Company Id")}</strong></TableCell>
                            <TableCell><strong>{t("License Name")}</strong></TableCell>
                            <TableCell><strong>{t("Request")}</strong></TableCell>
                            <TableCell><strong>{t("Version")}</strong></TableCell>
                            <TableCell><strong>{t("Error")}</strong></TableCell>
                            <TableCell><strong>{t("Comments")}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{dateFormatter(item.dateTime)}</TableCell>
                                <TableCell>{item.companyId}</TableCell>
                                <TableCell>{item.licenseName}</TableCell>
                                <TableCell>{item.call}</TableCell>
                                <TableCell>{item.version}</TableCell>
                                <TableCell>
                                    {item.error && (
                                    <Chip label='Error' style={{backgroundColor: '#ff0000'}} />)}
                                </TableCell>
                                <TableCell>{item.comments}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
            </>
            }
        </div>
    );
}

export default CompaniesTable;