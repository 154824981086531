import ApiServiceHttp from './apiServiceHttp';
import ApiServiceMock from './apiServiceMock';

export default class ApiService {

    static service = process.env.REACT_APP_ENV === 'test' ? ApiServiceMock : ApiServiceHttp;

    // User
    static async getUserInfo(idToken) {
        return await this.service.getUserInfo(idToken);
    };

    // Organizations
    static async getOrganizations(idToken) {
        return await this.service.getOrganizations(idToken);
    };

    static async getOrganization(idToken, id) {
        return await this.service.getOrganization(idToken, id);
    }

    static async saveOrganization(idToken, data) {
        return await this.service.saveOrganization(idToken, data);
    }

    static async updateOrganization(idToken, data) {
        return await this.service.updateOrganization(idToken, data);
    }

    static async deleteOrganization(idToken, data) {
        return await this.service.deleteOrganization(idToken, data);
    }

    // Companies
    static async getCompanies(idToken) {
        return await this.service.getCompanies(idToken);
    };

    static async getCompany(idToken, id) {
        return await this.service.getCompany(idToken, id);
    }

    static async saveCompany(idToken, data) {
        return await this.service.saveCompany(idToken, data);
    }

    static async updateCompany(idToken, data) {
        return await this.service.updateCompany(idToken, data);
    }

    static async deleteCompany(idToken, data) {
        return await this.service.deleteCompany(idToken, data);
    }

    // Licenses
    static async getLicenses(idToken) {
        return await this.service.getLicenses(idToken);
    };

    static async getLicense(idToken, id) {
        return await this.service.getLicense(idToken, id);
    }

    static async saveLicense(idToken, data) {
        return await this.service.saveLicense(idToken, data);
    }

    static async updateLicense(idToken, data) {
        return await this.service.updateLicense(idToken, data);
    }

    static async deleteLicense(idToken, data) {
        return await this.service.deleteLicense(idToken, data);
    }

    // License History
    static async getLicensesHistory(idToken, start, end, companyId, licenseName) {
        return await this.service.getLicensesHistory(idToken, start, end, companyId, licenseName);
    }

    // Tasks
    static async getTasksHistory(idToken, start, end, companyId, licenseName, notSuccessful) {
        return await this.service.getTasksHistory(idToken, start, end, companyId, licenseName, notSuccessful);
    }

    static async getTasksByVehicle(idToken, start, end, companyId, licenseName) {
        return await this.service.getTasksByVehicle(idToken, start, end, companyId, licenseName);
    }

    // Requests
    static async getRequests(idToken, start, end, companyId, licenseName) {
        return await this.service.getRequests(idToken, start, end, companyId, licenseName);
    }

    // Analysis data
    static async getAnalysisData(idToken, start, end, companyId, licenseName, service, type) {
        return await this.service.getAnalysisData(idToken, start, end, companyId, licenseName, service, type);
    }

    // GDC
    static async getGdcRoutes(idToken) {
        return await this.service.getGdcRoutes(idToken);
    }

    static async getGdcRoute(idToken, companyId, routeId) {
        return await this.service.getGdcRoute(idToken, companyId, routeId);
    }

    static async saveGdcRoute(idToken, data) {
        return await this.service.saveGdcRoute(idToken, data);
    }

    static async deleteGdcRoute(idToken, companyId, routeId) {
        return await this.service.deleteGdcRoute(idToken, companyId, routeId);
    }

    static async simulateUserLocations(idToken, companyId, routeId) {
        return await this.service.simulateUserLocations(idToken, companyId, routeId);
    }

    // GDC Users
    static async getGdcUsers(idToken) {
        return await this.service.getGdcUsers(idToken);
    }

    // GDC History
    static async getGdcHistory(idToken, start, end, companyId, licenseName) {
        return await this.service.getGdcHistory(idToken, start, end, companyId, licenseName);
    }

    // Service tests
    static async getServiceTests(idToken) {
        return await this.service.getServiceTests(idToken);
    }

    static async runTest(idToken, testRun) {
        return await this.service.runTest(idToken, testRun);
    }

    static async getMapTesters(idToken) {
        return await this.service.getMapTesters(idToken);
    }
}
