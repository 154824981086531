import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';

const RequestChart = ({ fetchData }) => {
  const [data, setData] = useState([]);
  const [hours, setHours] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const requests = await fetchData();

        // List of last 24 hours from now
        const data =  Array.from({ length: 24 });
        for (let i = 0; i < 24; i++) {
          data[i] = {};
          data[i].hour = dayjs().subtract(23 - i, 'hour').hour().toString();
          data[i].count = 0;  
        }

        for (const request of requests) {
          try
          {
            let requestDate = request.date;
            if (!requestDate.endsWith('Z')) requestDate += 'Z';
            const hour = dayjs(requestDate).hour();
            const item = data.find(d => d.hour === hour.toString());
            item.count++;
          }
          catch (error) {
            console.error('Error parsing date:', error);
          } 
        }
        setData(data);
        
  } catch (error) {
        console.error('Error fetching data:', error);
   }
    };

    getData();
  }, [fetchData]);

  return (
    <Plot
      data={[
        {
          x: data.map(d => d.hour + ":00"),
          y: data.map(d => d.count),
          type: 'bar',
        },
      ]}
      layout={{ 
        height: 150, 
        margin: { l: 40, r: 40, t: 20, b: 60 },
        font: { size: 9 },
       }}
    />
  );
};

export default RequestChart;