import React from 'react';
import { useContext } from 'react';
import ServiceStatus from './ServiceStatus';
import ApiService from '../../services/apiService';
import { UserContext } from '../../contexts/UserProvider';
import { Container, Box } from '@mui/material';
import dayjs from 'dayjs';

const getLast24Hours = () => {
    const end = dayjs().add(2, 'hour').toISOString();
    const start = dayjs().subtract(24, 'hour').toISOString();
    return { start, end };
  };

const fetchOrtService = async (idToken) => {
  const { start, end } = getLast24Hours();
  const licensesHistory = await ApiService.getLicensesHistory(idToken, start, end, '', '');
  licensesHistory.forEach(request => {
    request.date = request.dateTime;
  });
  const requests = await ApiService.getRequests(idToken, start, end, '', '');
  requests.forEach(request => {
    request.date = request.dateTime;
  });
  return licensesHistory.concat(requests);
};

const fetchGdcService = async (idToken) => {
  const { start, end } = getLast24Hours();
  const response = await ApiService.getGdcHistory(idToken, start, end, '', '');
  response.forEach(request => {
    request.date = request.dateTime;
  });
  return response;
};

const fetchOptService = async (idToken, version) => {
  const { start, end } = getLast24Hours();
  const response = await ApiService.getTasksHistory(idToken, start, end, '', '', false);
  const filteredResponse = response.filter(request => request.version.startsWith(version));
  filteredResponse.forEach(request => {
    request.date = request.addedTime;
  });
  return filteredResponse;
};

const ServiceDashboard = () => {
    const userInfo = useContext(UserContext);

  return (
    <div>
      <h1>Service Status</h1>
      <Container >
        <ServiceStatus
          title="Service v2"
          healthCheckUrl="https://opt.onerbox.com/service/2/api/checkstatus"
          fetchData={() => fetchOrtService(userInfo?.idToken)}
        />
        <ServiceStatus
          title="GDC v1"
          healthCheckUrl="https://gdc.onerbox.com/service/1/api/checkstatus"
          fetchData={() => fetchGdcService(userInfo?.idToken)}
        />
        <ServiceStatus
          title="OPT v2"
          healthCheckUrl="https://opt.onerbox.com/service/2/api/checkstatus"
          fetchData={() => fetchOptService(userInfo?.idToken, '2')}
        />
        <ServiceStatus
          title="OPT v3"
          healthCheckUrl="https://opt.onerbox.com/service/3/api/checkstatus"
          fetchData={() => fetchOptService(userInfo?.idToken, '3')}
        />
        {/* <ServiceStatus
          title="Pack v1"
          healthCheckUrl="https://pack.onerbox.com/service/1/api/otr/CheckStatus"
          fetchData={() => []}
        /> */}
        {/* <ServiceStatus
          title="Email v1"
          healthCheckUrl="https://email.onerbox.com/service/1/api/email/CheckStatus"
          fetchData={() => []}
        /> */}
      </Container>
    </div>
  );
};

export default ServiceDashboard;