// src/components/Dashboard.js
import React, { useState } from 'react';
import SidePanel from './SidePanel';
import { useTranslation } from 'react-i18next';
import Organizations from './licensing/organizations/Organizations';
import Companies from './licensing/companies/Companies';
import Licenses from './licensing/licenses/Licenses';
import LicensesHistory from './licensing/history/LicensesHistory';
import TasksHistory from './tasks/TasksHistory';
import TasksByVehicle from './tasksByVehicle/TasksByVehicle';
import Requests from './requests/Requests';
import AnalysisData from './analysisData/AnalysisData';
import Routes from './gdc/routes/Routes';
import Users from './gdc/users/Users';
import GdcHistory from './gdc/history/GdcHistory';
import ServiceTests from './serviceTests/ServiceTests';
import ServiceDashboard from './serviceStatus/ServiceDashboard';
import MapTesters from './mapTesters/MapTesters';

function Dashboard() {
    const { t } = useTranslation();
    const [selectedView, setSelectedView] = useState('serviceStatus');

    const renderSelectedView = () => {
        switch (selectedView) {
            case 'organizations':
                return <Organizations />;
            case 'companies':
                return <Companies />;
            case 'licenses':
                return <Licenses />;
            case 'licensesHistory':
                return <LicensesHistory />;
            case 'tasksHistory':
                return <TasksHistory />;
            case 'tasksByVehicle':
                return <TasksByVehicle />;
            case 'requests':
                return <Requests />;
            case 'analysisData':
                return <AnalysisData />;
            case 'gdcRoutes':
                return <Routes />;
            case 'gdcUsers':
                return <Users />;
            case 'gdcHistory':
                return <GdcHistory />;
            case 'serviceStatus':
                return <ServiceDashboard />;
            case 'serviceTests':
                return <ServiceTests />;
            case 'mapTesters':
                return <MapTesters />;
            default:
                return null;
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <SidePanel onSelectView={setSelectedView} />
            <div style={{ flex: 1, padding: '20px' }}>
                {renderSelectedView()}
            </div>
        </div>
    );
}

export default Dashboard;