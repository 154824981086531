import React, { useState, useEffect, useContext } from 'react';
import TasksByVehiclePivot from './TasksByVehiclePivot';
import { Button, Box, LinearProgress, Container, TextField, InputAdornment, FormControl, 
    InputLabel, Select, MenuItem, Grid } from '@mui/material';
import ApiService from '../../services/apiService';
import { UserContext } from '../../contexts/UserProvider';
import { useTranslation } from 'react-i18next';
import AppSnackbar from './../AppSnackbar';
import DateTime from '../controls/DateTime';
import dayjs from 'dayjs';

function TasksByVehicle() {
    const { t } = useTranslation();
    const userInfo = useContext(UserContext);
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState(null);
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').format('L LTS'));
    const [endDate, setEndDate] = useState(dayjs().format('L LTS'));
    const [companyIds, setCompanyIds] = useState([]);
    const [licenseNames, setLicenseNames] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [selectedLicenseName, setSelectedLicenseName] = useState('');

    useEffect(() => {
        requestItems(userInfo?.idToken, startDate, endDate);
        fetchCompanyIds();
    }, [userInfo?.idToken]);

    useEffect(() => {
        if (selectedCompanyId) {
            fetchLicenseNames(selectedCompanyId);
        }
    }, [selectedCompanyId]);

    const requestItems = async (idToken, start, end, selectedCompanyId, selectedLicenseName) => {
        setIsLoading(true);
        setItems(null);
        try {
            const response = await ApiService.getTasksByVehicle(idToken, start, end, selectedCompanyId, selectedLicenseName);
            setItems(response);
        } catch (error) {
            setItems(null);
            setSnackbarInfo({ message: t(error.message), severity: error.severity });
        }
        setIsLoading(false);
    };

    const fetchCompanyIds = async () => {
        try {
            const response = await ApiService.getCompanies(userInfo?.idToken);
            const companyIds = response.map(company => company.companyId);
            companyIds.sort((a, b) => { return a.localeCompare(b); });
            setCompanyIds(companyIds);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchLicenseNames = async (companyId) => {
        try {
            const response = await ApiService.getLicenses(userInfo?.idToken);
            const companyLicenses = response.filter(license => license.companyId === companyId);
            const licenseNames = companyLicenses.map(license => license.name);
            const sortedNames = licenseNames.sort((a, b) => { return a.localeCompare(b); });
            setLicenseNames(sortedNames);
        } catch (error) {
            console.error(error);
        }
    };

    const handleRefresh = () => {
        requestItems(userInfo?.idToken, startDate, endDate, selectedCompanyId, selectedLicenseName);
    };

    const handleSelectedCompanyId = (e) => {
        setSelectedCompanyId(e);
        fetchLicenseNames(e);
        setSelectedLicenseName('');
    }
        
    return (
        <>
        { isLoading && (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>)}
            <Container maxWidth="xl">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <h1>{t("Tasks History by Vehicle")}</h1>
                    <Button variant='contained' onClick={handleRefresh}>{t("Refresh")}</Button>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DateTime label={t("Start Date")} value={startDate} onChange={setStartDate} />
                        </Grid>
                        <Grid item>
                            <DateTime label={t("End Date")} value={endDate} onChange={setEndDate} />
                        </Grid>
                    </Grid>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <FormControl variant="outlined" fullWidth sx={{ mr: 2 }}>
                        <InputLabel>{t("Company ID")}</InputLabel>
                        <Select
                            value={selectedCompanyId}
                            onChange={(e) => handleSelectedCompanyId(e.target.value)}
                            label={t("Company ID")}
                        >
                            {companyIds.map((id) => (
                                <MenuItem key={id} value={id}>{id}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>{t("License Name")}</InputLabel>
                        <Select
                            value={selectedLicenseName}
                            onChange={(e) => setSelectedLicenseName(e.target.value)}
                            label={t("License Name")}
                        >
                            {licenseNames.map((name) => (
                                <MenuItem key={name} value={name}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <TasksByVehiclePivot items={items} />
            </Container>
            <AppSnackbar info={snackbarInfo} onClose={() => setSnackbarInfo(null)} />
        </>
    );
}

export default TasksByVehicle;